.controltBtnWrap {
  position: fixed;
  bottom: 60px;
  width: 100%;
  display: flex;
  padding: 10px 0 10px 0;
  background-color: #fffcfc00;
}

.butSub {
  border-radius: 5px;
  margin: 0 100px 0px -5px;

}

.butRest {
  border-radius: 5px;
  margin: 0 100px 0px 5px;
}

.topbackGroupClocl {
  height: 100%;
  background-color: #fdf7f7;
  border: 1px solid #fdf7f7;
}

.adm-list-item {
  background-color: #fdf7f7;
}