.butSub {
  border-radius: 5px;
  margin: 0 100px 0px -5px;

}

.butRest {
  border-radius: 5px;
  margin: 0 100px 0px 5px;
}

.adm-input-element {
  background: white;
}