.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;


  .top {
    flex: 0;
    border-bottom: solid 1px var(--adm-border-color);
  }

  .navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .body {
    flex: 1;
    margin-top: 45px;
    background-color: rgb(236, 232, 232);
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .bottom {
    flex: 0;
    border-top: solid 1px var(--adm-border-color);
  }
}

.tabBut {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}