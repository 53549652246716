.orderBtnUPDown {
    bottom: 60px;
    width: 100%;
    display: flex;
    padding: 10px 0 10px 0;
}

.butSub {
    border-radius: 5px;
    margin: 0 100px 0px -5px;

}

.butRest {
    border-radius: 5px;
    margin: 0 100px 0px 5px;
}

.orderBtnPad {
    bottom: 60px;

    button {
        border-radius: 5px;
        width: 90vw;

    }

    &.adm-space {
        display: inline;
        text-align: center;
        --gap: 0px;
        --gap-vertical: var(--gap);
        --gap-horizontal: var(--gap);
    }

    .adm-space {
        display: inline-flex;
        --gap: 8px;
        --gap-vertical: var(--gap);
        --gap-horizontal: var(--gap);
        padding: 10px 0 10px 0;
    }
}