.header {
  margin-left: 10px;
}

.eventBtnWrap {
  position: fixed;
  bottom: 60px;
  width: 100%;
  display: flex;
  padding: 10px 0 10px 0;
  background-color: #fffcfc00;

  button {
    border-radius: 5px;
    margin: 0 100px 0px 0px;
  }
}

.adm-form .adm-form-footer {
  padding: 0px 0px;
}


.adm-selector {
  --color: var(--adm-color-fill-content);
  --checked-color: #e7f1ff;
  --text-color: var(--adm-color-text);
  --checked-text-color: var(--adm-color-primary);
  --border: none;
  --checked-border: none;
  --border-radius: 2px;
  --padding: 10px 48px;
  --gap: 8px;
  ---gap: var(--gap);
  ---gap-horizontal: var(--gap-horizontal, var(--gap));
  ---gap-vertical: var(--gap-vertical, var(--gap));
  overflow: hidden;
  font-size: var(--adm-font-size-7);
  line-height: 1.4;
}

.adm-space {
  --gap: 12px;
}