.imageTop {
    margin-top: 20px;
}

.orderMargin {
    margin-right: 0px;
}


.card {
    width: 90%;
    position: relative;
    left: 2%;
    bottom: 30px;
}